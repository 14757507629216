import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { searchCities } from '../api';
import '../assets/styles/pages/UserPage.css';
import NavBar from '../components/NavBar'; // Adjust the path as necessary

const UserPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const navigate = useNavigate();
    const { user, logout } = useContext(AuthContext);

    const handleSearchChange = async (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.length >= 2) {
            setIsSearching(true);
            try {
                const data = await searchCities(term);
                setSearchResults(data.cities);
            } catch (error) {
                console.error('Failed to search cities:', error);
                setSearchResults([]);
            }
            setIsSearching(false);
        } else {
            setSearchResults([]);
        }
    };

    const handleCitySelect = (slug) => {
        navigate(`/map/${slug}`);
        setSearchTerm('');
        setSearchResults([]);
        window.dispatchEvent(new CustomEvent('resetMapFilters'));
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <div className="user-page">
            <header>
                <h1>Welcome, {user ? user.email : 'Guest'}</h1>
                {user && <button onClick={handleLogout}>Logout</button>}
            </header>
            <main>
                <section className="search-section">
                    <h2>Search Cities</h2>
                    <div className="search-container">
                        <input
                            type="search"
                            placeholder="Search cities..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                        />
                        {isSearching && (
                            <div className="search-loading">Searching...</div>
                        )}
                        {searchResults.length > 0 && (
                            <ul className="search-results">
                                {searchResults.map((city) => (
                                    <li
                                        key={city.slug}
                                        onClick={() =>
                                            handleCitySelect(city.slug)
                                        }>
                                        {city.nameWithState}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </section>
                <section className="user-info">
                    <h2>Your Information</h2>
                    {user ? (
                        <div>
                            <p>Email: {user.email}</p>
                            {/* Add more user information here */}
                        </div>
                    ) : (
                        <p>Please log in to view your information.</p>
                    )}
                </section>
                <section className="quick-links">
                    <h2>Quick Links</h2>
                    <ul>
                        <li>
                            <Link to="/placeholder1">Link 1</Link>
                        </li>
                        <li>
                            <Link to="/placeholder2">Link 2</Link>
                        </li>
                        <li>
                            <Link to="/placeholder3">Link 3</Link>
                        </li>
                    </ul>
                </section>
            </main>
            <NavBar />
        </div>
    );
};

export default UserPage;
