import React, { useEffect, useRef, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';

const Marker = React.memo(({ map, location, onClick, isActive }) => {
    const markerRef = useRef(null);

    const createMarkerElement = useCallback(() => {
        const el = document.createElement('div');
        el.className = 'marker';

        let iconSrc;
        switch (location.type) {
            case 'Coffee Shop':
                iconSrc = '/assets/images/map/icon-cup.svg';
                break;
            case 'Both':
                iconSrc = '/assets/images/map/icon-both.svg';
                break;
            case 'Roastery':
                iconSrc = '/assets/images/map/icon-roaster.svg';
                break;
            default:
                iconSrc = '/assets/images/map/icon-cup.svg';
        }

        el.innerHTML = `
            <div class="marker-icon">
                <img src="${iconSrc}" alt="${location.type}" />
            </div>
            <div class="tooltip">${location.name}</div>
        `;
        return el;
    }, [location.type, location.name]);

    useEffect(() => {
        console.log('Marker effect running', { map, location, isActive });

        if (!map || !location.long || !location.lat) {
            console.log(
                'Cannot add marker: map or location coordinates missing',
                { map, location }
            );
            return;
        }

        console.log('Adding marker for location:', location.name);

        const element = createMarkerElement();

        const addMarkerToMap = () => {
            try {
                if (map.loaded()) {
                    const marker = new mapboxgl.Marker(element)
                        .setLngLat([location.long, location.lat])
                        .addTo(map);

                    if (onClick) {
                        element.addEventListener('click', () =>
                            onClick(location)
                        );
                    }

                    markerRef.current = marker;
                } else {
                    console.log('Map not loaded yet, retrying...');
                    setTimeout(addMarkerToMap, 100);
                }
            } catch (error) {
                console.error('Error adding marker to map:', error);
            }
        };

        addMarkerToMap();

        return () => {
            if (markerRef.current) {
                console.log('Removing marker for:', location.name);
                markerRef.current.remove();
            }
        };
    }, [map, location, onClick, createMarkerElement, isActive]);

    useEffect(() => {
        if (markerRef.current) {
            const element = markerRef.current.getElement();
            console.log('Updating marker active state:', isActive);
            if (isActive) {
                element.classList.add('is-active');
            } else {
                element.classList.remove('is-active');
            }
        }
    }, [isActive]);

    return null;
});

export default Marker;
