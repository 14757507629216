import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ConfirmEmailPage = () => {
    const [status, setStatus] = useState('Confirming...');
    const [loading, setLoading] = useState(true); // Add loading state
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const hash = location.hash; // Get the hash from the URL
        const confirmToken = new URLSearchParams(hash.slice(1)).get(
            'confirmation_token'
        );

        // Replace the confirmation logic with Firebase Auth logic
        if (confirmToken) {
            // Proceed with Firebase confirmation
            // firebase.auth().applyActionCode(confirmToken) // Example Firebase method
            //     .then(() => {
            //         setStatus('Email confirmed successfully!');
            //         setTimeout(() => navigate('/login'), 3000); // Redirect to login
            //     })
            //     .catch((error) => {
            //         setStatus('Failed to confirm email. Please try again.');
            //         console.error('Confirmation error:', error);
            //     })
            //     .finally(() => setLoading(false)); // Set loading to false after processing
        } else {
            setStatus('Invalid confirmation link.');
            setLoading(false);
        }
    }, [location, navigate]);

    return (
        <div>
            <h2>Email Confirmation</h2>
            {loading ? <p>Loading...</p> : <p>{status}</p>}{' '}
            {/* Show loading state */}
        </div>
    );
};

export default ConfirmEmailPage;
