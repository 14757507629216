import React, {
    useEffect,
    useRef,
    useState,
    useMemo,
    useCallback,
} from 'react';
import mapboxgl from 'mapbox-gl';
import '../assets/styles/pages/MapPage.css';
import '../assets/styles/components/MapMarker.css';
import Marker from './Marker';
import FilterBar from './FilterBar';
import MapLocationModal from './MapLocationModal';
import { debounce } from 'lodash';

const BOUNDS_PADDING = { top: 150, bottom: 250, left: 100, right: 100 };
const PLACEHOLDER_COORDINATES = {
    lat: 39.8283,
    lng: -98.5795,
};
const DEFAULT_ZOOM = 4;

const Map = ({ locations, isModalOpen }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [mapReady, setMapReady] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [filters, setFilters] = useState({
        roaster: '',
        rating: 0,
    });
    const [filterBoundsControl, setFilterBoundsControl] = useState({
        roaster: true,
        rating: false,
    });
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [roasters, setRoasters] = useState([]);

    const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    const filterFunction = useCallback(
        (location) => {
            return Object.entries(filters).every(([key, value]) => {
                switch (key) {
                    case 'roaster':
                        return (
                            value === '' ||
                            location.roasters.some(
                                (r) =>
                                    (typeof r === 'object' &&
                                        r.slug === value) ||
                                    r === value
                            )
                        );
                    case 'rating':
                        return location.ratingGoogle >= value;
                    default:
                        return true;
                }
            });
        },
        [filters]
    );

    const filteredLocations = useMemo(() => {
        return locations.filter(filterFunction);
    }, [locations, filterFunction]);

    useEffect(() => {
        const allRoasters = locations.flatMap((location) => location.roasters);
        const uniqueRoasters = allRoasters.reduce((acc, roaster) => {
            const key = typeof roaster === 'object' ? roaster.slug : roaster;
            if (!acc[key]) {
                acc[key] = roaster;
            }
            return acc;
        }, {});

        const sortedRoasters = Object.values(uniqueRoasters).sort((a, b) => {
            const nameA = typeof a === 'object' ? a.name : a;
            const nameB = typeof b === 'object' ? b.name : b;
            return nameA.localeCompare(nameB);
        });

        setRoasters(sortedRoasters);
    }, [locations]);

    useEffect(() => {
        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [
                    PLACEHOLDER_COORDINATES.lng,
                    PLACEHOLDER_COORDINATES.lat,
                ],
                zoom: DEFAULT_ZOOM,
                accessToken: MAPBOX_ACCESS_TOKEN,
            });

            mapRef.current.on('load', () => {
                setMapReady(true);
                setMapLoaded(true);
            });
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null;
            }
        };
    }, [MAPBOX_ACCESS_TOKEN]);

    useEffect(() => {
        if (!mapRef.current || !mapReady) return;

        // Reset map view when locations change
        const bounds = new mapboxgl.LngLatBounds();
        locations.forEach((location) => {
            if (location.long && location.lat) {
                bounds.extend([location.long, location.lat]);
            }
        });

        if (!bounds.isEmpty()) {
            mapRef.current.fitBounds(bounds, {
                padding: BOUNDS_PADDING,
                maxZoom: 15,
                duration: 1000,
            });
        }

        // Reset filters when locations change
        setFilters({
            roaster: '',
            rating: 0,
        });
    }, [locations, mapReady]);

    useEffect(() => {
        const handleResetFilters = () => {
            setFilters({
                roaster: '',
                rating: 0,
            });
            setFilterBoundsControl({
                roaster: true,
                rating: false,
            });
        };

        window.addEventListener('resetMapFilters', handleResetFilters);

        return () => {
            window.removeEventListener('resetMapFilters', handleResetFilters);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (mapRef.current) {
                mapRef.current.resize();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!mapRef.current || !mapReady || filteredLocations.length === 0)
            return;

        const shouldUpdateBounds = Object.entries(filterBoundsControl).some(
            ([key, shouldBound]) => shouldBound && filters[key] !== ''
        );

        if (shouldUpdateBounds) {
            const bounds = new mapboxgl.LngLatBounds();
            filteredLocations.forEach((location) => {
                if (location.long && location.lat) {
                    bounds.extend([location.long, location.lat]);
                }
            });

            if (!bounds.isEmpty()) {
                mapRef.current.fitBounds(bounds, {
                    padding: BOUNDS_PADDING,
                    maxZoom: 15,
                    duration: 1000,
                });
            }
        }
    }, [filteredLocations, mapReady, filters, filterBoundsControl]);

    const handleMarkerClick = useCallback((location) => {
        setSelectedLocation(location);
    }, []);

    const memoizedMarkers = useMemo(() => {
        if (!mapRef.current || !mapReady) {
            console.log('Map not ready for markers');
            return null;
        }

        console.log(
            'Creating markers for',
            filteredLocations.length,
            'locations'
        );
        return filteredLocations.map((location) => (
            <Marker
                key={location.id || location.webflowId}
                map={mapRef.current}
                location={location}
                onClick={handleMarkerClick}
                isActive={
                    selectedLocation && selectedLocation.id === location.id
                }
            />
        ));
    }, [mapReady, filteredLocations, handleMarkerClick, selectedLocation]);

    const debouncedSetFilters = useMemo(
        () =>
            debounce((newFilters) => {
                setFilters(newFilters);
            }, 300),
        [setFilters]
    );

    const toggleFilterBoundsControl = useCallback((filterName) => {
        setFilterBoundsControl((prev) => ({
            ...prev,
            [filterName]: !prev[filterName],
        }));
    }, []);

    console.log('Rendering Map component', {
        mapReady,
        markersCount: filteredLocations.length,
    });

    return (
        <div>
            <FilterBar
                filters={filters}
                setFilters={debouncedSetFilters}
                roasters={roasters}
                filterBoundsControl={filterBoundsControl}
                toggleFilterBoundsControl={toggleFilterBoundsControl}
            />
            <div
                ref={mapContainerRef}
                className={`map-container ${isModalOpen ? 'modal-open' : ''}`}
            />
            {mapReady && mapLoaded && memoizedMarkers}
            {selectedLocation && (
                <React.Suspense fallback={<div>Loading...</div>}>
                    <MapLocationModal
                        location={selectedLocation}
                        onClose={() => setSelectedLocation(null)}
                    />
                </React.Suspense>
            )}
        </div>
    );
};

export default Map;
