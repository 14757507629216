import React, { useContext, useState } from 'react';
import { AuthContext } from '../AuthContext';

const SignupForm = () => {
    const { signup, message } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        signup(email, password); // Call the placeholder signup function
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Sign Up</h2>
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <button type="submit">Sign Up</button>
            {message && <p>{message}</p>} {/* Display the message */}
        </form>
    );
};

export default SignupForm;
