import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const fetchCityData = async (slug) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/cities/${slug}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching city data:', error);
        throw error;
    }
};

export const fetchLocationsByCitySlug = async (slug) => {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/v1/locations-by-city/${slug}`,
            {
                headers: {
                    'x-api-key': API_KEY,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching locations:', error);
        throw error;
    }
};

// New function to fetch all cities
export const fetchAllCities = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/cities`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all cities:', error);
        throw error;
    }
};

// New function for city search
export const searchCities = async (searchTerm) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/search-cities`, {
            params: { q: searchTerm },
            headers: {
                'x-api-key': API_KEY,
            },
        });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error searching cities:', error);
        throw error;
    }
};
