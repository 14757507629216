import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';
import '../assets/styles/pages/HomePage.css';

const locations = [
    'aurora-co',
    'seoul-kr',
    'rome-it',
    'albuquerque-nm',
    'garden-grove-ca',
    'eugene-or',
    'steamboat-springs-co',
    'loveland-co',
    'vancouver-wa',
    'westminster-co',
    'pueblo-co',
    'greeley-co',
    'billings-mt',
    'aspen-co',
    'thornton-co',
    'centennial-co',
    'lakewood-co',
    'arvada-co',
    'fargo-nd',
    'fort-worth-tx',
    'aurora-il',
    'st-albert-ab',
    'okotoks-ab',
    'cochrane-ab',
    'manitou-springs-co',
    'santa-barbara-ca',
    'grand-junction-co',
    'glenwood-springs-co',
    'littleton-co',
    'frisco-co',
    'golden-co',
    'longmont-co',
    'evergreen-co',
    'paonia-co',
    'basalt-co',
    'eagle-co',
    'los-angeles-ca',
    'phoenix-az',
    'breckenridge-co',
    'boulder-co',
    'sedona-az',
    'montreal-qc',
    'portland-or',
    'winnipeg-mb',
    'regina-sk',
    'colorado-springs-co',
    'denver-co',
    'boston-ma',
];

// Sort the locations alphabetically
const sortedLocations = locations.sort();

const HomePage = () => {
    return (
        <div>
            <NavBar />
            <h1>Welcome to the Homepage</h1>
            <ul>
                {sortedLocations.map((slug) => (
                    <li key={slug}>
                        <Link to={`/map/${slug}`}>
                            {slug.replace(/-/g, ' ')}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default HomePage;
