import React, { useState } from 'react';
import '../assets/styles/components/MapLocationModal.css';

const MapLocationModal = ({ location, onClose }) => {
    const [userRating, setUserRating] = useState(0);

    if (!location) return null;

    const handleRatingClick = (rating) => {
        setUserRating(rating);
        // Handle the rating submission logic here
        console.log('User rating:', rating);
    };

    const smallSquareImage = location.images.find(
        (image) => image.type === 'small-square'
    );

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="map-location-modal">
                <button className="map-location-modal-close" onClick={onClose}>
                    ×
                </button>
                {smallSquareImage && (
                    <div className="location-image-container">
                        <img
                            src={smallSquareImage.url}
                            alt={smallSquareImage.alt || `${location.name}`}
                            className="location-image"
                        />
                    </div>
                )}
                <div className="image-overlay">
                    <h2>{location.name}</h2>
                </div>
                <p>{location.address}</p>
                {location.phone && <p>Phone: {location.phone}</p>}
                <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        location.name + ' ' + location.address
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    View on Google Maps
                </a>

                <h3>Coffee Roasters:</h3>
                <div className="roaster-list">
                    {location.roasters && location.roasters.length > 0 ? (
                        location.roasters.map((roaster) => (
                            <a
                                key={roaster._id}
                                href={`https://www.roast.love/r/${roaster.slug}`}
                                className="roaster-card"
                                target="_blank"
                                rel="noopener noreferrer">
                                <img
                                    src={roaster.logoUrl}
                                    alt={`${roaster.name} logo`}
                                    className="roaster-logo"
                                />
                                <div className="roaster-info">
                                    <h4>{roaster.name}</h4>
                                    <p>Rating: {roaster.rating}</p>
                                </div>
                            </a>
                        ))
                    ) : (
                        <div className="roaster-card placeholder">
                            <p>No roasters available</p>
                        </div>
                    )}
                </div>

                <div className="feedback-card">
                    <h3>Do you like the coffee here?</h3>
                    <div className="rating">
                        {[1, 2, 3, 4, 5].map((heart) => (
                            <span
                                key={heart}
                                className={`heart ${
                                    userRating >= heart ? 'selected' : ''
                                }`}
                                onClick={() => handleRatingClick(heart)}>
                                &#10084;
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MapLocationModal;
