import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchLocationsByCitySlug } from '../api';
import Map from '../components/Map';
import NavBar from '../components/NavBar';
import '../assets/styles/pages/MapPage.css';

const MapPage = () => {
    const { slug } = useParams();
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getLocations = async () => {
            try {
                // console.log(
                //     'API Base URL:',
                //     process.env.REACT_APP_API_BASE_URL
                // );
                // console.log('Fetching locations for slug:', slug);
                const data = await fetchLocationsByCitySlug(slug);
                // console.log('Received data:', data);
                if (!data || !Array.isArray(data.locations)) {
                    throw new Error('Invalid data format received');
                }
                setLocations(data.locations);
            } catch (err) {
                console.error('Error fetching locations:', err);
                setError(`Error loading locations: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        getLocations();
    }, [slug]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading locations: {error}</div>;

    return (
        <>
            <NavBar />
            <Map locations={locations} />
        </>
    );
};

export default MapPage;
