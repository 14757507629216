import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { searchCities } from '../api';
import '../assets/styles/components/NavBar.css';
import { AuthContext } from '../AuthContext';

const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();
    const { user, logout } = useContext(AuthContext);
    console.log('NavBar rendering, user:', user);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
    };

    const handleSearchChange = async (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        console.log('Search term:', term); // Add this line

        if (term.length >= 2) {
            setIsSearching(true);
            try {
                const data = await searchCities(term);
                console.log('Search results:', data.cities); // Add this line
                setSearchResults(data.cities);
            } catch (error) {
                console.error('Failed to search cities:', error);
                setSearchResults([]);
            }
            setIsSearching(false);
        } else {
            setSearchResults([]);
        }
    };

    const handleCitySelect = (slug) => {
        navigate(`/map/${slug}`);
        setSearchTerm('');
        setSearchResults([]);
        // Add this line to reset filters when a new city is selected
        window.dispatchEvent(new CustomEvent('resetMapFilters'));
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchRef.current &&
                !searchRef.current.contains(event.target)
            ) {
                setSearchResults([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className="navbar">
            <div className="navbar-content">
                <div className="navbar-logo">
                    <Link to="/">
                        <img src="/path-to-your-logo.png" alt="Logo" />
                    </Link>
                </div>
                <div className="navbar-search" ref={searchRef}>
                    <input
                        type="search"
                        placeholder="Search cities..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                    />
                    {isSearching && (
                        <div className="search-loading">Searching...</div>
                    )}
                    {searchResults.length > 0 && (
                        <ul className="search-results">
                            {searchResults.map((city) => (
                                <li
                                    key={city.slug}
                                    onClick={() => handleCitySelect(city.slug)}>
                                    {city.nameWithState}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="navbar-links-desktop">
                    {user ? (
                        <>
                            <Link to="/user">My Account</Link>
                            <button onClick={handleLogout}>Logout</button>
                            {console.log('Rendering logged in state')}
                        </>
                    ) : (
                        <>
                            <Link to="/login">Login</Link>
                            <Link to="/signup">Sign Up</Link>
                            {console.log('Rendering logged out state')}
                        </>
                    )}
                    <Link to="/placeholder1">Link 1</Link>
                    <Link to="/placeholder2">Link 2</Link>
                    <Link to="/placeholder3">Link 3</Link>
                </div>
                <div className="navbar-menu-mobile">
                    <button onClick={toggleMenu}>
                        {isMenuOpen ? 'Close' : 'Menu'}
                    </button>
                </div>
            </div>
            {isMenuOpen && (
                <div className="navbar-links-mobile">
                    {user ? (
                        <>
                            <Link to="/user" onClick={toggleMenu}>
                                My Account
                            </Link>
                            <button
                                onClick={() => {
                                    handleLogout();
                                    toggleMenu();
                                }}>
                                Logout
                            </button>
                        </>
                    ) : (
                        <>
                            <Link to="/login" onClick={toggleMenu}>
                                Login
                            </Link>
                            <Link to="/signup" onClick={toggleMenu}>
                                Sign Up
                            </Link>
                        </>
                    )}
                    <Link to="/placeholder1" onClick={toggleMenu}>
                        Link 1
                    </Link>
                    <Link to="/placeholder2" onClick={toggleMenu}>
                        Link 2
                    </Link>
                    <Link to="/placeholder3" onClick={toggleMenu}>
                        Link 3
                    </Link>
                </div>
            )}
        </nav>
    );
};

export default NavBar;
