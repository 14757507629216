import React from 'react';
import '../assets/styles/components/FilterBar.css';

const FilterBar = ({ filters, setFilters, roasters }) => {
    const handleFilterChange = (filterName, value) => {
        setFilters({ ...filters, [filterName]: value });
    };

    const ratingOptions = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

    const renderFilter = (filterName, options, label) => {
        switch (filterName) {
            case 'roaster':
                return (
                    <div className="filter-item">
                        <label className="filter-label">{label}:</label>
                        <select
                            className="filter-select"
                            value={filters[filterName]}
                            onChange={(e) =>
                                handleFilterChange(filterName, e.target.value)
                            }>
                            <option value="">All Roasters</option>
                            {options.map((roaster, index) => (
                                <option
                                    key={index}
                                    value={
                                        typeof roaster === 'object'
                                            ? roaster.slug
                                            : roaster
                                    }>
                                    {typeof roaster === 'object'
                                        ? roaster.name
                                        : roaster}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            case 'rating':
                return (
                    <div className="filter-item">
                        <label className="filter-label">{label}:</label>
                        <select
                            className="filter-select"
                            value={filters[filterName]}
                            onChange={(e) =>
                                handleFilterChange(
                                    filterName,
                                    parseFloat(e.target.value)
                                )
                            }>
                            <option value={0}>All Ratings</option>
                            {options.slice(1).map((rating) => (
                                <option key={rating} value={rating}>
                                    {`${rating}+ Stars`}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            // Add more cases for new filter types here
            default:
                return null;
        }
    };

    return (
        <div className="filter-bar">
            {renderFilter('roaster', roasters, 'Roaster')}
            {renderFilter('rating', ratingOptions, 'Rating')}
            {/* Add more filters here by calling renderFilter with the appropriate arguments */}
        </div>
    );
};

export default FilterBar;
